<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "Resources",
};
</script>

<style lang="scss" scoped>
.wrapper {
  height: 100%;
}
</style>
